import { ComponentProps } from 'react'
import { RegisterOptions, Validate, ValidationRule } from 'react-hook-form'

import Input from './RHFInput'

import { FLIGHT_CODE_REGEX } from 'constants/flight'

export const TIME_REGEX = /[0-9]:[0-9]/
export const DATE_REGEX =
  /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/ /* taken from 'components/Common/Form/DateInput' */
export const ISO_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})/

export const VALID_URL_REGEX =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/

/* RFC 5322 Offical Standard Email Regex */
export const VALID_EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const VALID_URL_NO_SCHEME_REGEX =
  /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/

export const FLIGHT_CONFIRMATION_CODE_REGEX = /^[a-zA-Z0-9]{1,7}$/

const INVALID_URL_MESSAGE = 'Please enter a valid URL'

export const prependUrlScheme = (url: string) => {
  if (VALID_URL_NO_SCHEME_REGEX.test(url)) {
    return 'https://' + url
  }

  return url
}

const urlValidator: Record<string, Validate<any>> = {
  noSpace: (url: string | undefined) => {
    return url?.includes(' ') ? INVALID_URL_MESSAGE : true
  },
  isValidUrl: (url: string | undefined) => {
    if (url) {
      if (VALID_URL_REGEX.test(url)) {
        return true
      }

      return INVALID_URL_MESSAGE
    }

    return true
  },
}

export const formOptions = {
  mode: 'onBlur',
  shouldFocusError: true,
  reValidateMode: 'onBlur',
} as const

export const required: ValidationRule<boolean> = {
  message: 'Please fill in this field.',
  value: true,
}

export const timePatternValidation: ValidationRule<RegExp> = {
  value: TIME_REGEX,
  message: 'Please select a time.',
}

export const flightConfirmationCodeConfig: RegisterOptions = {
  pattern: {
    value: FLIGHT_CONFIRMATION_CODE_REGEX,
    message: 'Please enter your 6 digit confirmation.',
  },
}

export const emailConfig: RegisterOptions = {
  required: 'Please enter email address.',
  pattern: {
    message: 'Please enter a valid email address.',
    value: VALID_EMAIL_REGEX,
  },
}

export const flightCarrierCodeConfig: RegisterOptions = {
  required: {
    value: true,
    message: 'Please enter a valid flight number',
  },
  pattern: {
    value: FLIGHT_CODE_REGEX,
    message: 'Please enter a valid flight number',
  },
}

export const urlRegisterConfig: RegisterOptions = {
  validate: urlValidator,
  setValueAs: prependUrlScheme,
}

export const airportConfig: RegisterOptions = {
  pattern: {
    message: 'Please select an airport.',
    value: /[A-Z]{3}/,
  },
}

export const priceRegisterConfig: RegisterOptions = {
  setValueAs: (v) =>
    v === '' || v === null || v === undefined ? undefined : parseFloat(v),
  min: 0,
}

export const priceFieldProps: Partial<ComponentProps<typeof Input>> = {
  type: 'number',
  step: 0.01,
}
