import moment, { Moment } from 'moment'
import { useMemo } from 'react'

import { nonNullable } from 'lib/array/arrayUtils'
import { useTrip } from 'tripPlanner/hooks/api'
import { makeDate } from 'tripPlanner/utils'

interface Props {
  activeDate?: string
  tripStartDate?: Moment
  tripEndDate?: Moment
  minDate?: Moment
  maxDate?: Moment
}

const useDefaultDates = ({
  activeDate,
  tripStartDate,
  tripEndDate,
  minDate,
  maxDate,
}: Props): {
  start?: Moment
  end?: Moment
} => {
  return useMemo(() => {
    let defaultStartDate: Moment | undefined = undefined
    let defaultEndDate: Moment | undefined = undefined

    if (activeDate) {
      defaultStartDate = makeDate(activeDate)

      if (tripEndDate && tripEndDate.diff(defaultStartDate, 'day') <= 14) {
        defaultEndDate = tripEndDate
      }
    } else if (
      tripStartDate &&
      tripEndDate &&
      tripEndDate.diff(tripStartDate, 'day') <= 14
    ) {
      defaultStartDate = tripStartDate
      defaultEndDate = tripEndDate
    }

    if (minDate) {
      if (minDate.isAfter(defaultEndDate)) {
        return {}
      }

      defaultStartDate = moment.max(nonNullable([minDate, defaultStartDate]))
    }

    if (maxDate?.isBefore(defaultEndDate)) {
      return {}
    }

    if (defaultStartDate?.isBefore(moment())) {
      return {}
    }

    return {
      start: defaultStartDate,
      end: defaultEndDate,
    }
  }, [activeDate, tripStartDate, tripEndDate, minDate, maxDate])
}

export default useDefaultDates

export function useDefaultDatePickerMonthYear({ tripId }: { tripId: string }) {
  const { data: trip } = useTrip({ tripId })

  if (trip?.startDate) {
    return {
      defaultMonth: trip.startDate.month(),
      defaultYear: trip.startDate.year(),
    }
  }

  return {
    defaultMonth: undefined,
    defaultYear: undefined,
  }
}
