import React, {
  useCallback,
  useEffect,
  useState,
  ComponentProps,
  ChangeEventHandler,
  FocusEventHandler,
  useMemo,
} from 'react'
import { useFormContext } from 'react-hook-form'

import BaseAutocompleteInput, {
  BaseAutocompleteProps,
} from './BaseAutocompleteInput'
import NoResultsCopy from '../Search/NoResultsCopy'
import Input from '../TripModal/TripItems/Common/Fields/RHFInput'

import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import {
  TripGooglePlaceAutocompletePrediction,
  TripGooglePlaceDetail,
} from 'tripPlanner/api/places/types'
import useTripPlaceAutocomplete from 'tripPlanner/hooks/Google/useTripPlaceAutocomplete'
import { useTripPlaceDetail } from 'tripPlanner/hooks/Google/useTripPlaceDetail'
import {
  AutocompletePlaceParam,
  AutocompletionRequest,
} from 'tripPlanner/types/google'

const DEBOUNCE_DELAY = 300
const CHARACTER_THRESHOLD = 1
const NO_RESULTS_MESSAGE = 'Try a different search phrase'

export interface TripAddressAutocompleteOption {
  label: string
  value: string
  prediction?: TripGooglePlaceAutocompletePrediction
  details?: TripGooglePlaceDetail
  address_components?: google.maps.GeocoderAddressComponent
}

interface Props extends ComponentProps<typeof Input> {
  autocompleteTypes: AutocompletePlaceParam // `tripPlanner/types/google.ts` for more info
  details: Array<keyof TripGooglePlaceDetail>
  initPlaceId?: string
  label?: string
  name: string
  onPlaceSelect: (option: TripAddressAutocompleteOption) => void
  placeholder?: string
  autocompletionReqParam?: AutocompletionRequest
}

const predictionSelector: BaseAutocompleteProps<TripGooglePlaceAutocompletePrediction>['presentationSelector'] =
  (prediction) => ({
    title: prediction.structured_formatting.main_text,
    subtitle: prediction.structured_formatting.secondary_text,
  })

// Only for Trip planner usage
const TripAddressAutocomplete = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      autocompleteTypes,
      details,
      initPlaceId,
      name,
      onBlur,
      onChange,
      onFocus,
      onPlaceSelect,
      autocompletionReqParam,
      helpText,
      ...rest
    },
    ref,
  ) => {
    const [selectedPrediction, setSelectedPrediction] =
      useState<TripGooglePlaceAutocompletePrediction | null>(null)
    const [searchPhrase, setSearchPhrase] = useState('')

    const { data: initialPlace } = useTripPlaceDetail({
      placeId: initPlaceId,
      fields: details,
    })

    useEffect(() => {
      if (initialPlace) {
        setSearchPhrase(initialPlace.name || '')
      }
    }, [initialPlace])

    const { setValue } = useFormContext()
    const autoCompleteRequest = useMemo(() => {
      return {
        input: searchPhrase,
        types: autocompleteTypes,
        components: autocompletionReqParam?.componentRestrictions?.country.map(
          (code) => `country:${code}`,
        ),
      }
    }, [
      autocompletionReqParam?.componentRestrictions,
      searchPhrase,
      autocompleteTypes,
    ])

    const currentSearchPhrase = useAutoUpdatingRef(searchPhrase)

    const {
      hasResults,
      isLoading: predictionsLoading,
      predictions,
    } = useTripPlaceAutocomplete(autoCompleteRequest, currentSearchPhrase, {
      debounceDelay: DEBOUNCE_DELAY,
      characterThreshold: CHARACTER_THRESHOLD,
    })

    const { data: selectedPlace } = useTripPlaceDetail({
      placeId: selectedPrediction?.place_id,
      fields: details,
    })

    const handleSelect = useCallback(
      (option: TripGooglePlaceAutocompletePrediction) => {
        setSelectedPrediction(option)
        setValue(name, option.structured_formatting.main_text, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      },
      [name, setValue],
    )

    const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setSearchPhrase('')
        onFocus?.(e)
      },
      [onFocus],
    )

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setSearchPhrase(e.currentTarget.value)
        onChange?.(e)
      },
      [onChange],
    )

    useEffect(() => {
      if (selectedPrediction && selectedPlace) {
        onPlaceSelect({
          label: selectedPrediction.structured_formatting.main_text,
          value: selectedPrediction.place_id,
          prediction: selectedPrediction,
          details: selectedPlace,
        })
      }
    }, [onPlaceSelect, selectedPlace, selectedPrediction])

    return (
      <BaseAutocompleteInput
        {...rest}
        canRenderDropdown={searchPhrase.length > CHARACTER_THRESHOLD}
        canRenderNoResult={!hasResults && !predictionsLoading}
        name={name}
        noResultComponent={
          <NoResultsCopy
            message={NO_RESULTS_MESSAGE}
            searchPhrase={searchPhrase}
          />
        }
        onChange={handleChange}
        onFocus={handleFocus}
        onOptionSelect={handleSelect}
        options={predictions}
        presentationSelector={predictionSelector}
        ref={ref}
        isLoading={predictionsLoading}
        helpText={helpText}
      />
    )
  },
)

export default TripAddressAutocomplete
