import { useEffect } from 'react'
import { useController, UseFormReturn } from 'react-hook-form'

const GEO_ERROR_MSG = 'Please select an address or place.'

export default function useEnsureGeoPlace(
  methods: UseFormReturn<any>,
  placeKey: string,
  depKeys: Array<string>,
  enabled: boolean = true,
) {
  const pointKey = `${placeKey}.point`
  const { clearErrors, control, setError, formState, watch, getValues } =
    methods
  const type = watch('type')

  const {
    fieldState: { error },
  } = useController({
    name: pointKey,
    control,
    shouldUnregister: true,
    rules: {
      required: GEO_ERROR_MSG,
    },
  })

  useEffect(() => {
    clearErrors(depKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (error?.message) {
      for (const name of depKeys) {
        if (getValues(name)?.length > 0) {
          if (enabled) {
            setError(name, {
              type: 'deps',
              message: GEO_ERROR_MSG,
            })
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.message, depKeys, formState.submitCount])

  return error
}
