import { rem } from 'polished'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import {
  priceFieldProps,
  priceRegisterConfig,
  urlRegisterConfig,
} from './Fields/config'
import ConfirmedBooking from './Fields/ConfirmedBooking'
import RHFDatePickerInput from './Fields/RHFDatePickerInput'
import Input from './Fields/RHFInput'
import TextArea from './Fields/RHFTextArea'
import ShowMore from './Fields/ShowMore'
import TimeInput from './Fields/TimeInput'
import { useDefaultDatePickerMonthYear } from './Hooks/useDefaultDates'
import LegacyThingsToDoAddressField from './LegacyThingsToDoAddresses/LegacyThingsToDoAddressField'

import Group from 'components/utils/Group'

const PriceInput = styled(Input)`
  width: ${rem(100)};
`

interface Props {
  tripId: string
}

function CustomThingToDoFields({ tripId }: Props) {
  const methods = useFormContext()
  const { watch, control, register } = methods

  const { defaultMonth, defaultYear } = useDefaultDatePickerMonthYear({
    tripId,
  })

  const place = watch('place')

  return (
    <Group direction="vertical" gap={16}>
      <Input {...register('name')} label="Name" placeholder="Enter a name" />

      <LegacyThingsToDoAddressField key={place} tripId={tripId} />

      <Group direction="horizontal" horizontalAlign="stretch" gap={16}>
        <RHFDatePickerInput
          name="startDate"
          label="Date"
          defaultMonth={defaultMonth}
          defaultYear={defaultYear}
        />
        <TimeInput
          {...register('startTime')}
          label="Time"
          placeholder="Select time"
        />
      </Group>

      <Controller
        name="isBooked"
        control={control}
        render={({ field }) => (
          <ConfirmedBooking
            name={field.name}
            toggled={!!field.value}
            onToggle={() => field.onChange(!field.value)}
          />
        )}
      />
      <ShowMore>
        <PriceInput
          {...register('price', { ...priceRegisterConfig })}
          label="Price"
          placeholder="0.00"
          {...priceFieldProps}
        />

        <Input
          {...register('url', { ...urlRegisterConfig })}
          label="Website"
          placeholder="https://"
        />

        <TextArea
          {...register('notes')}
          placeholder="Add note here"
          label="Note"
          size="tall"
          resize="vertical"
        />
      </ShowMore>
    </Group>
  )
}

export default CustomThingToDoFields
