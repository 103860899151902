// A list of supported autocomplete request types can be found here.
// https://developers.google.com/maps/documentation/places/web-service/supported_types

import { TripGooglePlaceDetail } from 'tripPlanner/api/places/types'

type FiveArray<T> = [T] | [T, T] | [T, T, T] | [T, T, T, T] | [T, T, T, T, T]

// TABLE 1 (Add them as you need them)
// https://developers.google.com/maps/documentation/places/web-service/supported_types#table1
export const PLACE_TYPE_1 = [
  'bar',
  'bus_station',
  'cafe',
  'light_rail_station',
  'restaurant',
  'subway_station',
  'train_station',
  'transit_station',
  'travel_agency',
] as const

// TABLE 2 (Add them as you need them)
// https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
export const PLACE_TYPE_2 = [
  'country',
  'landmark',
  'locality',
  'premise',
  'subpremise',
  'street_address',
  'street_number',
  'point_of_interest',
  'establishment',
  'food',
  'route',
] as const

// TABLE 3 (Add them as you need them)
// https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
export const PLACE_TYPE_3 = ['address', 'establishment'] as const

// If you use a 'type' value from the 1st or 2nd table, you may specify up to 5 from each.
type RequestType1 = (typeof PLACE_TYPE_1)[number]
type RequestType2 = (typeof PLACE_TYPE_2)[number]

// If you use a 'type' value from the 3rd table, you can only specify 1.
type RequestType3 = (typeof PLACE_TYPE_3)[number]

export type AutocompletePlaceParam =
  | FiveArray<RequestType1 | RequestType2>
  | [RequestType3]
  | []

export interface AddItemFormProps {
  tripId: string
  selectedDate?: string
}

export type PlaceResult = google.maps.places.PlaceResult

export const ESTABLISHMENT_RESPONSE_DETAILS: Array<keyof TripGooglePlaceDetail> = [
  'place_id',
  'address_components',
  'types',
  'name',
  'geometry',
  'website',
  'formatted_address',
  'international_phone_number',
  'rating',
  'user_ratings_total',
]

export const ADDRESS_RESPONSE_DETAILS: Array<keyof TripGooglePlaceDetail> = [
  'place_id',
  'address_components',
  'types',
  'name',
  'geometry',
  'formatted_address',
  'website',
]

export interface AutocompletionRequest {
  /**
   * add other properties as needed
   * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
   */

  componentRestrictions?: { country: Array<string> }
}
