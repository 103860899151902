import React from 'react'
import { useFormContext } from 'react-hook-form'

import DualAddressField from './DualAddressField'
import GeoAddressField from './GeoAddressField'

interface Props {
  tripId: string
}

function LegacyThingsToDoAddressField({ tripId }: Props) {
  const methods = useFormContext()

  const { watch } = methods
  const type = watch('type')

  return (
    <>
      {type === 'ATTRACTION' && <GeoAddressField tripId={tripId} />}
      {type === 'RESTAURANT_BAR' && <GeoAddressField tripId={tripId} />}
      {type === 'EVENT' && <GeoAddressField tripId={tripId} />}
      {type === 'THING_TO_DO' && <DualAddressField />}
    </>
  )
}

export default LegacyThingsToDoAddressField
