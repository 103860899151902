import { rem } from 'polished'
import React, {
  MouseEvent,
  useCallback,
  useState,
  ComponentProps,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react'
import styled from 'styled-components'

import Input from '../TripModal/TripItems/Common/Fields/RHFInput'

import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import ListItem from 'components/Luxkit/List/ListItem'

const Container = styled.div`
  position: relative;
`

// Fake dropdown to look native to the fake modal
const FakeDropDownContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + ${rem(8)});
  max-height: calc(100vh - 200px);
`

type ItemElement = HTMLAnchorElement | HTMLButtonElement

export interface BaseAutocompleteProps<T> extends ComponentProps<typeof Input> {
  canRenderDropdown: boolean
  canRenderNoResult: boolean
  isLoading: boolean
  noResultComponent: React.ReactNode
  onClickOutside?: () => void
  onDropdownClose?: () => void
  onOptionSelect: (option: T) => void
  onEnterPress?: () => void
  options: Array<T>
  presentationSelector: (option: T) => ComponentProps<typeof ListItem>
  isFullScreen?: boolean
  itemStartIcon?: (option: T) => React.ReactNode
  itemEndIcon?: (option: T) => React.ReactNode
  hasGutter?: boolean
}

function BaseAutocompleteInput<T>(
  {
    canRenderDropdown,
    canRenderNoResult,
    isLoading,
    noResultComponent,
    onClickOutside,
    onDropdownClose,
    onFocus,
    onOptionSelect,
    options,
    presentationSelector,
    onEnterPress,
    helpText,
    isFullScreen = false,
    itemStartIcon,
    itemEndIcon,
    ...rest
  }: BaseAutocompleteProps<T>,
  ref: React.Ref<HTMLInputElement>,
) {
  const [isFocused, setIsFocused] = useState(false)
  const [inDropdownContext, setInDropdownContext] = useState(false)
  const triggerRef = useRef<HTMLInputElement>(null)

  const handleClickOutside = useCallback(() => {
    if (!inDropdownContext && !isFullScreen) {
      setIsFocused(false)
    }
  }, [inDropdownContext, isFullScreen])

  const handleOnFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsFocused(true)
      onFocus?.(e)
    },
    [onFocus],
  )

  const handleItemFocus: React.FocusEventHandler<ItemElement> =
    useCallback(() => {
      setIsFocused(true)
    }, [])

  const onSelect = useCallback(
    (e: MouseEvent<ItemElement>, option: T) => {
      onOptionSelect?.(option)
      setIsFocused(false)
    },
    [onOptionSelect],
  )

  useEffect(() => {
    if (!isFocused) {
      onDropdownClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused])

  useImperativeHandle(ref, () => triggerRef.current!)

  const forceBlur = useCallback(() => {
    setIsFocused(false)
    triggerRef.current?.blur()
  }, [])

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && onEnterPress) {
        onEnterPress()
        forceBlur()
      }
    },
    [forceBlur, onEnterPress],
  )

  const dropdownContents = (
    <>
      {!isLoading &&
        options.map((option, i) => (
          <ListItem
            key={i}
            onClick={(e) => onSelect(e, option)}
            onFocus={handleItemFocus}
            size="medium"
            startIcon={itemStartIcon ? itemStartIcon(option) : null}
            endIcon={itemEndIcon ? itemEndIcon(option) : null}
            {...presentationSelector(option)}
          />
        ))}
      {!isLoading && canRenderNoResult && noResultComponent}
      {isLoading && <LoadingIndicator inline />}
    </>
  )

  return (
    <Container
      onMouseEnter={() => setInDropdownContext(true)}
      onMouseLeave={() => setInDropdownContext(false)}
    >
      <Input
        {...rest}
        autoComplete="off"
        onFocus={handleOnFocus}
        onKeyUp={onEnterPress ? handleKeyPress : undefined}
        ref={triggerRef}
        helpText={helpText}
      />
      <DropdownList
        onClose={handleClickOutside}
        open={isFocused && canRenderDropdown && !isFullScreen}
        triggerRef={triggerRef}
        anchorRef={triggerRef}
        size="fill-anchor"
        data-testid="autocomplete-dropdown"
      >
        {dropdownContents}
      </DropdownList>
      {isFocused && canRenderDropdown && isFullScreen && (
        <FakeDropDownContainer>{dropdownContents}</FakeDropDownContainer>
      )}
    </Container>
  )
}

export default React.forwardRef(BaseAutocompleteInput) as <T>(
  props: BaseAutocompleteProps<T> & {
    ref?: React.ForwardedRef<HTMLInputElement>
  },
) => ReturnType<typeof BaseAutocompleteInput>
