import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import AddressDisplay from '../Fields/AddressDisplay'
import { required } from '../Fields/config'
import useEnsureGeoPlace from '../Hooks/useEnsureGeoPlace'

import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import TripAddressAutocomplete, {
  TripAddressAutocompleteOption,
} from 'tripPlanner/components/AutocompleteInputs/TripAddressAutocomplete'
import {
  ADDRESS_RESPONSE_DETAILS,
  AutocompletePlaceParam,
} from 'tripPlanner/types/google'
import { convertGoogleAddressComponentsToRegion } from 'tripPlanner/utils'

const AUTOCOMPLETE_TYPES: AutocompletePlaceParam = []
const START_DEP_KEYS: Array<string> = ['startPlace.name']
const END_DEP_KEYS: Array<string> = ['endPlace.name']

function DualAddressField() {
  const methods = useFormContext()
  const { watch, setValue, register } = methods

  useEnsureGeoPlace(methods, 'startPlace', START_DEP_KEYS)
  useEnsureGeoPlace(methods, 'endPlace', END_DEP_KEYS)

  const startPlace = watch('startPlace')

  // const endPlace = watch('endPlace')

  const handleAddressChange = useCallback(
    (
      placeName: 'startPlace' | 'endPlace',
      selectedOption: TripAddressAutocompleteOption,
    ) => {
      setValue(
        `${placeName}.name`,
        selectedOption.details?.name || selectedOption.label,
      )

      setValue(
        `${placeName}.type`,
        selectedOption.prediction ? 'GEO' : 'CUSTOM',
      )

      if (selectedOption.prediction) {
        const { place_id } = selectedOption.prediction
        setValue(`${placeName}.id`, place_id)
      }

      if (selectedOption.details) {
        const { geometry, formatted_address, website, address_components } =
          selectedOption.details

        if (address_components) {
          const region =
            convertGoogleAddressComponentsToRegion(address_components)
          if (region) {
            setValue(`${placeName}.region`, region)
          }
        }

        if (website) {
          setValue('url', website)
        }
        formatted_address && setValue(`${placeName}.address`, formatted_address)

        if (geometry?.location) {
          setValue(`${placeName}.point.lat`, geometry.location.lat)
          setValue(`${placeName}.point.lng`, geometry.location.lng)
        }
      }
      if (placeName === 'startPlace') {
        handleAddressChange('endPlace', selectedOption)
      }
    },
    [setValue],
  )

  const handleStartAddressChange = useCallback(
    (selectedOption: TripAddressAutocompleteOption) => {
      handleAddressChange('startPlace', selectedOption)
    },
    [handleAddressChange],
  )
  return (
    <div>
      <TripAddressAutocomplete
        {...register('startPlace.name', { required })}
        required
        label="Where?"
        placeholder="Search for an address"
        startIcon={<LineMapMarkerIcon />}
        initPlaceId={startPlace?.type === 'GEO' ? startPlace.id : undefined}
        autocompleteTypes={AUTOCOMPLETE_TYPES}
        details={ADDRESS_RESPONSE_DETAILS}
        onPlaceSelect={handleStartAddressChange}
      />
      {startPlace.address && (
        <AddressDisplay title="Address" value={startPlace.address} />
      )}
    </div>
  )
}

export default DualAddressField
