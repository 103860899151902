import { useContext } from 'react'
import { TWELVE_HOUR_FORMAT_REGIONS } from 'constants/config/region'

import GeoContext from 'contexts/geoContext'
import {
  SHORT_TIME_FORMAT_24_HOUR,
  SHORT_TIME_HOUR_FORMAT_AM_PM,
  SHORT_TIME_HOUR_FORMAT_AM_PM_UPPERCASE,
} from 'constants/dateFormats'

export function is12HourTimeRegion(regionCode: string): boolean {
  return TWELVE_HOUR_FORMAT_REGIONS.has(regionCode)
}

export function useIs12HourTimeRegion(): boolean {
  const { currentRegionCode } = useContext(GeoContext)
  return is12HourTimeRegion(currentRegionCode)
}

interface Params {
  upperCaseAmPm?: boolean
}
export function useRegionTimeFormat({ upperCaseAmPm = false }: Params = {}): string {
  const twelveHrFormat = upperCaseAmPm ? SHORT_TIME_HOUR_FORMAT_AM_PM_UPPERCASE : SHORT_TIME_HOUR_FORMAT_AM_PM
  return useIs12HourTimeRegion() ? twelveHrFormat : SHORT_TIME_FORMAT_24_HOUR
}
