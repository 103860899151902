import cn from 'clsx'
import { rem } from 'polished'
import React, { InputHTMLAttributes, PropsWithChildren } from 'react'
import { useController } from 'react-hook-form'
import styled from 'styled-components'

import InputLabelWrap from 'components/Common/Form/Input/InputLabelWrap'
import InputWrap from 'components/Common/Form/Input/InputWrap'
import { InputTextBase } from 'components/Luxkit/Typography/InputText'
import { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from 'components/Luxkit/Typography/Typography'

const IconWrapper = styled.div`
  display: flex;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;

  &.left {
    left: ${rem(12)};
  }

  &.right {
    right: ${rem(12)};
  }
`

const StyledInput = styled.input`
  ${InputTextBase}
  line-height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});
  height: ${rem(46)};
  width: 100%;
  border: none;
  background: transparent;
  transition: color 0.2s;
  padding: ${rem(12)};
  border-radius: ${(props) => props.theme.borderRadius.S};
  color: ${(props) => props.theme.palette.neutral.default.one};

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.palette.neutral.default.four};
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${(props) => props.theme.palette.neutral.default.two};
    }
  }

  /*
    Browser things like auto fill and validation pop ups use the
    actual input element as their target. We want this to look like
    the whole element - so we need our input to take up the whole container

    When there's icon, reserve space for them and then they will be
    absolutely positioned.

    Icons for inputs are always 24px sized
  */

  &.icon-left {
    padding-left: ${rem(48)};
  }

  &.icon-right {
    padding-right: ${rem(48)};
  }
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: React.ReactNode
  error?: string
  startIcon?: React.ReactElement
  endIcon?: React.ReactElement
  noValidationSpacing?: boolean
  noValidationMessage?: boolean
  helpText?: React.ReactNode
}

/**
 * @description This component removes the controlled nature of CP inputs and let's React Hook Form handle it.
 * You should use the 'register()' function to spread desired RHF props into the native input component.
 * @see `Form/TextInput.tsx` for original implementation.
 * @example
 * <Input
    {...register('place.name', { required: true })}
    label="Place name"
    placeholder="Your place name"
  />
 * */
const RHFInput = React.forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  (props, ref) => {
    const {
      className,
      label,
      style,
      noValidationMessage,
      noValidationSpacing,
      disabled,
      name,
      children,
      startIcon,
      endIcon,
      helpText,
      ...rest
    } = props

    const {
      fieldState: { error },
    } = useController({ name })

    return (
      <InputLabelWrap
        label={label}
        style={style}
        className={className}
        id={rest.id}
        required={rest.required}
      >
        <InputWrap
          className={className}
          error={error?.message || rest.error}
          disabled={disabled}
          noValidationSpacing={noValidationSpacing}
          noValidationMessage={noValidationMessage}
          helpText={helpText}
        >
          {startIcon && <IconWrapper className="left">{startIcon}</IconWrapper>}
          <StyledInput
            {...rest}
            name={name}
            ref={ref}
            disabled={disabled}
            className={cn({
              'icon-left': !!startIcon,
              'icon-right': !!endIcon,
            })}
          />
          {endIcon && <IconWrapper className="right">{endIcon}</IconWrapper>}
          {children}
        </InputWrap>
      </InputLabelWrap>
    )
  },
)

RHFInput.displayName = 'RHFInput'

export default RHFInput
