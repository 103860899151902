import { useCallback } from 'react'

import { useView } from './useView'

import { ViewStateActions } from 'tripPlanner/contexts/View/ViewState'

export default function useModalCallbacks() {
  const {
    viewDispatch,
    viewState: { hasManyViews },
  } = useView()

  const closeModal = useCallback(
    () => viewDispatch({ type: ViewStateActions.MODAL_CLOSE }),
    [viewDispatch],
  )

  const goBack = useCallback(
    () => viewDispatch({ type: ViewStateActions.MODAL_GO_BACK }),
    [viewDispatch],
  )

  return { closeModal, goBack: hasManyViews ? goBack : undefined }
}
