import { useQuery } from '@tanstack/react-query'

import * as PlaceDetailKeys from '../reactQueryKeys/placeDetails'

import { getGooglePlaceDetail } from 'tripPlanner/api/places'
import { TripGooglePlaceDetail } from 'tripPlanner/api/places/types'

type Args<T> = {
  placeId: string | undefined
  fields: Array<T>
}

// Only for Trip Planner usage
export const useTripPlaceDetail = <Field extends keyof TripGooglePlaceDetail>({
  placeId,
  fields,
}: Args<Field>) => {
  return useQuery({
    queryKey: PlaceDetailKeys.googlePlaceDetail(fields, placeId),
    queryFn: () => getGooglePlaceDetail({ placeId: placeId ?? '', fields }),
    enabled: !!placeId,
  })
}
