import cn from 'clsx'
import { rem } from 'polished'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import TextLink from 'components/Luxkit/TextLink'
import { useElementHeight } from 'hooks/useElementSize'

const Container = styled.div`
  position: relative;
  overflow: hidden;
  transition: max-height 0.2s;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${rem(32)};
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &.show-mask::after {
    opacity: 1;
  }
`

interface Props {
  text?: string
  children?: React.ReactNode
}

function ShowMore(props: Props) {
  const { text = 'Add additional details', children } = props
  const [expandedDetails, setExpandedDetails] = useState(false)

  const contentElement = useRef<HTMLDivElement>(null)
  const contentHeight = useElementHeight(contentElement) ?? 0

  return (
    <>
      <TextLink
        onClick={() => {
          setExpandedDetails((prev) => !prev)
        }}
        endIcon={
          <DropdownChevron direction={expandedDetails ? 'up' : 'down'} />
        }
      >
        {text}
      </TextLink>
      <Container
        className={cn({ 'show-mask': !expandedDetails })}
        style={{
          maxHeight: expandedDetails ? contentHeight : 0,
        }}
      >
        <div ref={contentElement}>{children}</div>
      </Container>
    </>
  )
}

export default ShowMore
