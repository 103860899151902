import React from 'react'

import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  searchPhrase: string
  message: string
}

export default function NoResultsCopy({ searchPhrase, message }: Props) {
  return (
    <div>
      <BodyText variant="medium" colour="neutral-two">
        Sorry, we couldn’t find results for{' '}
        <BodyText
          colour="neutral-one"
          as="span"
          variant="medium"
          weight="semi-bold"
        >
          '{searchPhrase}'
        </BodyText>
        .
      </BodyText>
      <BodyText variant="large" colour="neutral-two">
        {message}
      </BodyText>
    </div>
  )
}
