import cn from 'clsx'
import React, { TextareaHTMLAttributes } from 'react'
import { useController } from 'react-hook-form'
import styled from 'styled-components'

import InputLabelWrap from 'components/Common/Form/Input/InputLabelWrap'
import InputWrap from 'components/Common/Form/Input/InputWrap'
import { StyledTextArea as TextArea } from 'components/Common/Form/Input/TextArea'

const TextAreaWithHeight = styled(TextArea)`
  &.size-tall {
    height: 130px;
  }
`

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  label?: React.ReactNode
  error?: string
  noValidationSpacing?: boolean
  noValidationMessage?: boolean
  resize?: 'none' | 'both' | 'horizontal' | 'vertical'
  size?: 'default' | 'tall'
}

/**
 * @description This component removes the controlled nature of CP inputs and let's React Hook Form handle it.
 * You should use the 'register()' function to spread desired RHF props into the native textarea element.
 * @see `Form/TextArea.tsx` for original implemenation.
 * @example
 * // how to use onSetRef?
 * <TextArea
    {...register('place.name', { required: true })}
    label="Place name"
    placeholder="Your place name"
  />
 * */
const RHFTextArea = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLTextAreaElement>) => {
    const {
      className,
      label,
      style,
      noValidationMessage,
      noValidationSpacing,
      disabled,
      name,
      resize,
      size = 'default',
      ...rest
    } = props

    const {
      fieldState: { error },
    } = useController({ name })

    return (
      <InputLabelWrap
        label={label}
        style={style}
        className={className}
        id={rest.id}
        required={rest.required}
      >
        <InputWrap
          className={className}
          error={error?.message}
          disabled={disabled}
          noValidationSpacing={noValidationSpacing}
          noValidationMessage={noValidationMessage}
        >
          <TextAreaWithHeight
            ref={ref}
            disabled={disabled}
            name={name}
            className={cn(className, {
              [`resize-${resize}`]: resize,
              [`size-${size}`]: size,
            })}
            {...rest}
          />
        </InputWrap>
      </InputLabelWrap>
    )
  },
)

export default RHFTextArea
