import debounce from 'debounce-promise'
import { useCallback, useEffect, useMemo, useState } from 'react'

import arrayConcatDedupe from 'lib/array/arrayConcatDedupe'
import { EmptyArray } from 'lib/array/arrayUtils'
import {
  GooglePlaceAutocompleteRequest,
  getGooglePlaceAutocomplete,
} from 'tripPlanner/api/places'
import { TripGooglePlaceAutocompletePrediction } from 'tripPlanner/api/places/types'
import { getUniquePredictions } from 'tripPlanner/utils/places'

interface HookOptions {
  debounceDelay?: number
  characterThreshold?: number
  includeLocationInPredictions?: boolean
}

// Only for Trip Planner Usage
const useTripPlaceAutocomplete = (
  params: GooglePlaceAutocompleteRequest,
  currentSearchPhrase: React.MutableRefObject<string>,
  {
    debounceDelay = 300,
    characterThreshold = 1,
    includeLocationInPredictions = false,
  }: HookOptions = {},
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [predictions, setPredictions] =
    useState<Array<TripGooglePlaceAutocompletePrediction>>(EmptyArray)

  const fetchPredictions = useCallback(
    async({
      input,
      origin,
      locationBias,
      types,
      radius,
      components,
    }: GooglePlaceAutocompleteRequest) => {
      return getGooglePlaceAutocomplete({
        input,
        types,
        origin,
        locationBias,
        radius,
        components,
        includeLocationInPredictions,
      })
    },
    [includeLocationInPredictions],
  )

  const fetchMultiPredictions = useCallback(
    (request: GooglePlaceAutocompleteRequest) => {
      Promise.allSettled([
        fetchPredictions({
          ...request,
        }),
        fetchPredictions({
          ...request,
          components: undefined,
        }),
      ])
        .then((results) => {
          const predictions: Array<TripGooglePlaceAutocompletePrediction> =
            results.reduce((acc, result) => {
              if (result.status === 'fulfilled') {
                return arrayConcatDedupe(acc, result.value)
              }
              return acc
            }, EmptyArray)
          setPredictions(getUniquePredictions(predictions))
          setIsLoading(false)
        })
        .catch(() => {
          setIsError(true)
          setPredictions([])
        })
    },
    [fetchPredictions],
  )

  const getDebouncedMultiGooglePredictions = useMemo(
    () =>
      debounce((value: string) => {
        if (value === currentSearchPhrase.current) {
          fetchMultiPredictions({
            ...params,
            input: value,
          })
        }
      }, debounceDelay),
    [currentSearchPhrase, debounceDelay, fetchMultiPredictions, params],
  )

  const getDebouncedGooglePredictions = useMemo(
    () =>
      debounce(async(value: string) => {
        if (value === currentSearchPhrase.current) {
          setIsLoading(true)
          getGooglePlaceAutocomplete({
            ...params,
            input: value,
            includeLocationInPredictions,
          })
            .then((results) => {
              setPredictions(results || EmptyArray)
              setIsLoading(false)
            })
            .catch(() => {
              setIsError(true)
              setPredictions(EmptyArray)
            })
        }
      }, debounceDelay),
    [currentSearchPhrase, debounceDelay, includeLocationInPredictions, params],
  )

  useEffect(() => {
    if (params.input && params.input.length > characterThreshold) {
      setIsLoading(true)
      let getPredictionsFunc = getDebouncedGooglePredictions
      getPredictionsFunc =
        params.components &&
        params.components?.length > 0 &&
        params.components?.length <= 5 ?
          getDebouncedMultiGooglePredictions :
          getDebouncedGooglePredictions
      getPredictionsFunc(params.input)
    } else {
      setPredictions(EmptyArray)
    }
  }, [
    getDebouncedGooglePredictions,
    characterThreshold,
    getDebouncedMultiGooglePredictions,
    params.input,
    params.components,
  ])

  return {
    predictions,
    isLoading,
    isError,
    hasResults: predictions.length > 0,
  }
}

export default useTripPlaceAutocomplete
