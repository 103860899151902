import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import SwitchButton from 'components/Common/SwitchButton'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  toggled: boolean
  onToggle: () => void
  label?: string
  name?: string
}

const Container = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(24)};
`

const SwitchButtonContainer = styled.div`
  margin-right: ${rem(16)};
`

const DEFAULT_COPY = 'Mark as booked'

function ConfirmedBooking({
  toggled,
  onToggle,
  label = DEFAULT_COPY,
  name,
}: Props) {
  return (
    <Container>
      <SwitchButtonContainer>
        <SwitchButton
          toggled={toggled}
          onToggle={onToggle}
          name={name}
          ariaLabel="Mark as booked"
        />
      </SwitchButtonContainer>
      <BodyText variant="large" colour="neutral-one">
        {label}
      </BodyText>
    </Container>
  )
}

export default React.memo(ConfirmedBooking)
