import { useCallback } from 'react'

import { useView } from './useView'

import { View, ViewStateActions } from 'tripPlanner/contexts/View/ViewState'

const useOpenModal = () => {
  const { viewDispatch } = useView()

  const openModal = useCallback(
    <T extends {}>(
      component: View<T>['component'],
      props?: View<T>['props'],
      config?: View<T>['config'],
    ) => {
      viewDispatch({
        type: ViewStateActions.MODAL_OPEN,
        // @ts-ignore - I will fix `useOpenModal.tsx` & `ViewState.tsx` in a separate PR
        view: { component, props: props ?? {}, config },
      })
    },
    [viewDispatch],
  )

  return openModal
}

export default useOpenModal
