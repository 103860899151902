import { useMemo } from 'react'

import { useTripMetadata } from 'tripPlanner/hooks/api'
import { getCountryCodes } from 'tripPlanner/utils/places'

const GOOGLE_CODE_RESTRICTION_LENGTH = 5

export const useComponentRestrictions = (tripId: string) => {
  const { data } = useTripMetadata({ tripId })

  const componentRestrictions = useMemo(() => {
    const countryCodes = getCountryCodes(data)
    if (
      countryCodes.length > 0 &&
      countryCodes.length <= GOOGLE_CODE_RESTRICTION_LENGTH
    ) {
      return { country: countryCodes }
    }
    return undefined
  }, [data])
  return componentRestrictions
}
