import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import AddressDisplay from '../Fields/AddressDisplay'
import { required } from '../Fields/config'
import useEnsureGeoPlace from '../Hooks/useEnsureGeoPlace'

import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import TripAddressAutocomplete, {
  TripAddressAutocompleteOption,
} from 'tripPlanner/components/AutocompleteInputs/TripAddressAutocomplete'
import { useComponentRestrictions } from 'tripPlanner/hooks/Google/useComponentRestrictions'
import {
  AutocompletePlaceParam,
  ESTABLISHMENT_RESPONSE_DETAILS,
} from 'tripPlanner/types/google'
import { convertGoogleAddressComponentsToRegion } from 'tripPlanner/utils'

const GEO_DEP_KEYS: Array<string> = ['place.name']
const AUTOCOMPLETE_TYPES: AutocompletePlaceParam = []

interface Props {
  tripId: string
}

function GeoAddressField({ tripId }: Props) {
  const methods = useFormContext()
  useEnsureGeoPlace(methods, 'place', GEO_DEP_KEYS)
  const componentRestrictions = useComponentRestrictions(tripId)

  const autocompletionReqParam = useMemo(
    () => ({
      componentRestrictions,
    }),
    [componentRestrictions],
  )

  const { watch, setValue, register } = methods

  const place = watch('place')

  const handlePlaceChange = useCallback(
    (selectedOption: TripAddressAutocompleteOption) => {
      setValue(
        'place.name',
        selectedOption.details?.name || selectedOption.label,
      )

      if (selectedOption.prediction) {
        const { place_id: placeId } = selectedOption.prediction
        setValue('place.id', placeId)
        setValue('place.type', 'GEO')
      } else {
        setValue('place.type', 'CUSTOM')
      }

      if (selectedOption.details) {
        const {
          geometry,
          website,
          formatted_address: formattedAddress,
          international_phone_number: internationalPhoneNumber,
          address_components: addressComponents,
        } = selectedOption.details

        if (addressComponents) {
          const region =
            convertGoogleAddressComponentsToRegion(addressComponents)
          if (region) {
            setValue('place.region', region)
          }
        }

        if (website) {
          setValue('url', website)
        }

        setValue('phone', internationalPhoneNumber)
        formattedAddress && setValue('place.address', formattedAddress)

        if (geometry?.location) {
          setValue('place.point.lat', geometry.location.lat)
          setValue('place.point.lng', geometry.location.lng)
        }
      }
    },
    [setValue],
  )

  return (
    <div>
      <TripAddressAutocomplete
        {...register('place.name', { required })}
        required
        label="Where?"
        placeholder="Search for an address"
        startIcon={<LineMapMarkerIcon />}
        autocompleteTypes={AUTOCOMPLETE_TYPES}
        details={ESTABLISHMENT_RESPONSE_DETAILS}
        onPlaceSelect={handlePlaceChange}
        autocompletionReqParam={autocompletionReqParam}
      />
      {place?.address && (
        <AddressDisplay title="Address" value={place.address} />
      )}
    </div>
  )
}

export default GeoAddressField
