import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import InputLabelWrap from 'components/Common/Form/Input/InputLabelWrap'
import BodyText from 'components/Luxkit/Typography/BodyText'

const LabelWrap = styled(InputLabelWrap)`
  margin-bottom: ${rem(24)};
`

interface Props {
  title: string
  value: string
}

function AddressDisplay(props: Props) {
  return (
    <LabelWrap label={props.title} required>
      <BodyText variant="large">{props.value}</BodyText>
    </LabelWrap>
  )
}

export default AddressDisplay
