import { useContext } from 'react'

import ViewContext from 'tripPlanner/contexts/View/ViewContext'

export function useView() {
  const { state, dispatch } = useContext(ViewContext)

  if (dispatch === undefined || state === undefined) {
    throw new Error('useView must be used within a ViewContextProvider')
  }

  return { viewState: state, viewDispatch: dispatch }
}
