import { GetExploreItemsResponse } from '../explore/types'
import {
  GetGoogleImageResponse,
  GetGooglePlaceAutocompleteResponse,
  GetGooglePlaceDetailResponse,
  GetGooglePlaceImagesResponse,
  GetGooglePlaceRatingResponse,
  TripGooglePlaceDetail,
} from '../places/types'
import { baseUri, getOptions } from '../utils'

import request from 'api/requestUtils'

// This function is used to fetch a single image for a place when we already know the name of the resource
export async function getGooglePlacePhoto(
  name: string,
  accessToken?: string,
): Promise<GetGoogleImageResponse['result']> {
  const uri = `${baseUri}/places/photos`
  const params = new URLSearchParams([])
  params.append('name', name)
  const encodedUri = uri + '?' + params.toString()
  return request
    .get<GetGoogleImageResponse>(encodedUri, {
      ...getOptions(accessToken),
    })
    .then((response) => response.result)
}

// Trip Planner Usage only
// This function is used to fetch multiple images for a place when we only know the placeId
export async function getGooglePlaceImages(
  placeId: string,
  count: number = 1,
  orientation: 'landscape' | 'portrait' | 'all' = 'all',
  maxWidthPx?: number,
  tripId?: string,
  tripItemId?: string,
): Promise<GetGooglePlaceImagesResponse['result']> {
  const uri = `${baseUri}/places/${placeId}/photos`
  const params = new URLSearchParams([])
  params.append('count', count.toString())
  params.append('orientation', orientation)
  if (tripId) {
    params.append('tripId', tripId)
  }
  if (tripItemId) {
    params.append('tripItemId', tripItemId)
  }
  if (maxWidthPx) {
    params.append('maxWidthPx', maxWidthPx.toString())
  }
  const encodedUri = uri + '?' + params.toString()
  return request
    .get<GetGooglePlaceImagesResponse>(encodedUri, {
      ...getOptions(),
    })
    .then((response) => response.result)
}

export async function getGooglePlaceRating(placeId: string) {
  const uri = `${baseUri}/places/${placeId}/rating`
  return request
    .get<GetGooglePlaceRatingResponse>(uri, {
      ...getOptions(),
    })
    .then((response) => response.result)
}

export interface GooglePlaceAutocompleteRequest {
  input: string
  types?: Array<string>
  sessionToken?: string
  locationBias?: { lat: number; lng: number }
  radius?: number
  origin?: { lat: number; lng: number }
  components?: Array<string>
  includeLocationInPredictions?: boolean
}

export async function getGooglePlaceAutocomplete({
  input,
  types,
  sessionToken,
  locationBias,
  radius,
  origin,
  components,
  includeLocationInPredictions = false,
}: GooglePlaceAutocompleteRequest): Promise<
  GetGooglePlaceAutocompleteResponse['result']
> {
  const uri = `${baseUri}/places/autocomplete`
  const params = new URLSearchParams([])
  params.append('input', input)

  if (types) {
    types.forEach((type) => {
      params.append('types[]', type)
    })
  }
  if (sessionToken) {
    params.append('sessiontoken', sessionToken)
  }
  if (origin) {
    params.append('origin', `${origin.lat},${origin.lng}`)
  }
  if (locationBias) {
    // https://developers.google.com/maps/documentation/places/web-service/autocomplete#locationbias
    params.append(
      'locationbias',
      `circle:${radius || 10000}@${locationBias.lat},${locationBias.lng}`,
    )
  }
  if (radius) {
    params.append('radius', radius.toString())
  }
  if (components) {
    components.forEach((component) => {
      params.append('components[]', component)
    })
  }

  if (includeLocationInPredictions) {
    params.append('includeLocation', 'true')
  }

  const encodedUri = uri + '?' + params.toString()
  return request
    .get<GetGooglePlaceAutocompleteResponse>(encodedUri, {
      ...getOptions(),
    })
    .then((response) => response.result)
}

export interface GooglePlaceDetailRequest<
  Field extends keyof TripGooglePlaceDetail,
> {
  placeId: string
  fields?: Array<Field>
}
export async function getGooglePlaceDetail({
  placeId,
  fields,
}: GooglePlaceDetailRequest<
  keyof TripGooglePlaceDetail
>): Promise<TripGooglePlaceDetail> {
  const uri = `${baseUri}/places/${placeId}/detail`
  const params = new URLSearchParams([])
  if (fields) {
    fields.forEach((field) => {
      params.append('fields[]', field)
    })
  }
  const encodedUri = uri + '?' + params.toString()
  return request
    .get<GetGooglePlaceDetailResponse>(encodedUri, {
      ...getOptions(),
    })
    .then((response) => response.result)
}

// for now we are just using this for one placeId. But it is highly probable in a future iteration
// that we will be using this endpoint for multiple. the endpoint is robust enough to handle getting
// multiple place details with an array of place ids
export async function getGoogleExploreItemsPlaceDetails(
  placeIds: Array<string>,
): Promise<GetExploreItemsResponse['result']> {
  const uri = `${baseUri}/explore/places/details`
  const params = new URLSearchParams([])
  placeIds.forEach((placeId) => {
    params.append('placeIds[]', placeId)
  })

  const encodedUri = uri + '?' + params.toString()

  return request
    .get<GetExploreItemsResponse>(encodedUri, {
      ...getOptions(),
    })
    .then((response) => response.result)
}
