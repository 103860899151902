import { useEffect, useRef } from 'react'

/**
 * Convenience hook for when you want to be able to use a prop or state value in a useCallback
 * without having to regenerate the callback every time the value changes.
 */
function useAutoUpdatingRef<T>(value: T): React.MutableRefObject<T> {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  })

  return ref
}

export default useAutoUpdatingRef
